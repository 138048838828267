<template>
    <div>
        <v-slide-x-transition>
            <v-app-bar :color="barColor" class="pr-12 pl-4" app flat :dark="$store.state.appBarDark" :class="$store.state.scrollPortfolio">



    <v-menu offset-y class="main-menu">
      <template v-slot:activator="{ on, attrs }">

        <v-btn class="d-flex d-sm-none" icon text v-bind="attrs" v-on="on" @click="/*$store.state.menu = !$store.state.menu*/">
            <v-icon>menu</v-icon>
        </v-btn>


      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
            <router-link :to="'/'+$i18n.locale+'/'+item.link">
                <v-list-item-title>{{ $t('nav.'+item.title) }}</v-list-item-title>
            </router-link>
        </v-list-item>
      </v-list>
    </v-menu>




                <div class="mr-12 d-none d-sm-flex">
                    <v-btn :class="`${$i18n.locale === 'es'? 'lang-bolder' : ''}`" small icon color="secondary" @click="$i18n.locale = 'es'">ES</v-btn> - <v-btn :class="`${$i18n.locale === 'en'? 'lang-bolder' : ''}`"  small icon color="secondary" @click="$i18n.locale = 'en'">EN</v-btn>
                </div>
                <!-- <v-spacer></v-spacer> -->
                <div class="d-flex align-center ml-12">
                    <v-img v-if="$store.state.appBarColor == 'white' && !$vuetify.theme.dark" alt="Yntech Logo" class="shrink mr-2" contain src="@/assets/logo.png" transition="slide-x-transition" width="170"/>
                    <v-img v-else alt="Yntech Logo" class="shrink mr-2" contain src="@/assets/logo-white.png" transition="slide-x-transition" width="170"/>
                </div>
                <!-- <h1 class="display-1"><span class="font-weight-thin secondary--text">YN</span><span class="font-weight-medium">TECH</span></h1> -->
                <v-spacer></v-spacer>
                
                <v-btn @click.native="$scrollToTop" exact-active-class="navbar-link-active" text class="d-none d-sm-flex" :to="'/'+$i18n.locale+'/inicio'" v-html="this.navItem.home">Inicio</v-btn>
                <v-btn @click.native="$scrollToTop" exact-active-class="navbar-link-active" text class="d-none d-sm-flex" :to="'/'+$i18n.locale+'/portafolio'" v-html="this.navItem.portfolio">Portafolio</v-btn>
                <v-btn @click.native="$scrollToTop" exact-active-class="navbar-link-active" text class="mr-12 d-none d-sm-flex" :to="'/'+$i18n.locale+'/acerca-de'" v-html="this.navItem.about">Acerca de</v-btn>
                <v-btn @click.native="$scrollToTop" exact-active-class="navbar-link-active" text class="mx-12 d-none d-sm-flex" :to="'/'+$i18n.locale+'/contacto'"><span class="line black" :class="{'white': ($store.state.appBarDark || $vuetify.theme.dark)}"></span> <span v-html="this.navItem.contact">Contacto</span></v-btn>

            </v-app-bar>
        </v-slide-x-transition>
    </div>
</template>

<script>
export default {

    data: () => ({
      drawer: false,
      group: null,
      items: [
        { title: 'home', link: 'inicio' },
        { title: 'portfolio', link: 'portafolio' },
        { title: 'about', link: 'acerca-de' },
        { title: 'contact', link: 'contacto' },
      ]
    }),

    computed: {
        barColor(){
            if(this.$vuetify.theme.dark && this.$store.state.appBarColor != 'transparent')
                return null
            return this.$store.state.appBarColor
        },
        lang(){
            return this.$i18n.locale
        },
        navItem(){
            return{
               home : this.$i18n.t("nav.home"),
               portfolio: this.$i18n.t("nav.portfolio"),
               about: this.$i18n.t("nav.about"),
               contact: this.$i18n.t("nav.contact")
            }
        }
    },
    watch: {
        lang(newVal, oldVal){
            console.log(newVal, oldVal)
            const route = this.$route.fullPath
            const redirect = route.replace(oldVal, newVal)
            this.$router.push(redirect)
        }
    }
}
</script>

<style>
.navbar-link-active{
    font-weight: 600 !important;
    color: #95b200 !important;
}
.v-btn.navbar-link-active::before{
    background-color: transparent !important;
}
span.line{
    width: 45px;
    height: 2px;
    margin-right: 5px;
    border-radius: 10px;
    /* background-color: black; */
}
</style>