//const ua = navigator.userAgent.toLowerCase();

/*function browser(extension) {
  var ext = "";
  if (ua.indexOf("safari") != -1) {
    if (ua.indexOf("chrome") > -1) {
      ext = extension[0]; // Chrome
    } else {
      ext = extension[1]; // Safari
    }
  } else {
    ext = extension[0];
  }
  return ext;
}*/

const bgImage = {
    methods: {
      bgPortrait(image, extension) {
        let bg = null;
        if (image == null) {
          bg = "background-color:white;";
        } else {
          bg =
            "background-image: url(  " +
            require("@/assets/" + image + "." + extension) +
            "); background-size: cover; background-position: center;";
        }
        return bg;
      }
    }
  };
  
  const image = {
    methods: {
      image(image, extension) {
        const defaultImage =
          "https://p7.hiclipart.com/preview/711/621/180/error-computer-icons-orange-error-icon.jpg"; // just set default
        try {
          return require("@/assets/" + image + "." + extension);
        } catch (e) {
          return defaultImage;
        }
      },
      imageParallax(image, extension) {
        const defaultImage =
          "https://p7.hiclipart.com/preview/711/621/180/error-computer-icons-orange-error-icon.jpg"; // just set default
  
        if (image != null && extension != null) {
          try {
            return require("@/assets/" + image + "." + extension);
          } catch (e) {
            return defaultImage;
          }
        } else {
          return null;
        }
      }
    }
  };
  
  export { bgImage, image };
  