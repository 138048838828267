import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    offsetTop: 0,
    appBarColor: 'white',
    appBarDark: false,
    footerColor: 'white',
    showScrollBtn: true,
    scrollPortfolio : '',
    mediaUrl: process.env.NODE_ENV === 'production' ? '/yntech/' : '',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
