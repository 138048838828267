<template>
    <v-content>
        <v-container class="pa-0" fluid>
            <v-card flat tile class="d-flex align-center flex-column height-70">
                <v-card-text>
                    <v-row no-gutters>
                        <v-col class="offset-md-1">
                            <router-link to="/" @click.native="$scrollToTop">
                                <v-btn text class="font-weight-bold">{{$t("buttons.goback")}} <span class="line black ml-2" :class="{'white': ($store.state.appBarDark || $vuetify.theme.dark)}"></span></v-btn>
                            </router-link>
                        </v-col>
                        <v-col cols="2" class="center-text ">
                            <v-img :src="image('products/indexmerca/logotipo-indexmerca-blanco','png')" width="100%"></v-img>
                        </v-col>
                        <v-col cols="1"></v-col>
                    </v-row>
                </v-card-text>
                <v-card-text class="py-0">
                    <v-row justify="center" no-gutters>
                        <v-col cols="9" class="display-2 secondary--text">
                            <span class="nova-font" v-html="this.navItem.phase0.title">Sistema</span>
                            <div>
                                <span class="display-4 font-weight-bold">
                                    <span class="nova-font">INDEXMERCA</span>
                                </span> 
                                <span class="nova-font"> ERP</span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="8" class="offset-md-1 title greytxt--text">
                            <div class="nova-font" v-html="this.navItem.phase0.text">
                                Lleva la evaluación de servicios al siguiente nivel, <b>Sistema Indexmerca</b> es una herramienta de gestión para empresas especializadas en el rubro, <b>gestiona un ejército de evaluadores desde una misma plataforma, administra tu cartera de clientes y productos</b>, crea encuestas a la medida de tus necesidades, programa y da seguimiento a cada evaluación de manera detallada, además emite y compara los resultados de tus evaluaciones
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
            </v-card>
            <!-- <v-card height="100%" width="100%" flat tile>
                <v-card-text>
                    
                </v-card-text>
            </v-card> -->
            <v-card id="content-section" height="100%" width="100%" flat tile class="">
                <v-card-text class="px-md-0">
                    <v-row no-gutters align="center">
                        <v-col md="6" class="flexbox-right">
                            <img :src="image('products/indexmerca/imagen-sistema-indexmerca','jpg')" width="50%" />
                        </v-col>
                        <v-col md="3" class="offset-md-1">
                            <div class="display-2 secondary--text font-weight-bold text-uppercase">
                                <span class="nova-font" v-html="this.navItem.phase1.title">El mejor <br> aliado</span>
                            </div>
                            <div class="title">
                                <div class="nova-font" v-html="this.navItem.phase1.text">
                                    Para la evaluación de servicios y mystery shopping, sistema indexmerca es un ERP especializado para auxiliar empresas de estos rubros, con él podrás gestionar eficientemente los recursos necesarios para operar tu negocio.
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card height="100%" width="100%" flat tile class="">
                <v-card-text class="px-md-0">
                    <v-row no-gutters align="center">
                        <v-col md="4" class="offset-md-3">
                            <div class="display-2 secondary--text font-weight-bold text-uppercase">
                            </div>
                            <div class="title">
                                <div class="nova-font" v-for="(list,index) in this.navItem.phase2.text" :key="index">
                                    <div class="mb-6">
                                        - <span v-html="list"></span>
                                    </div>                                
                                </div>
                            </div>
                        </v-col>
                        <v-col md="2" class="offset-md-1">
                            <v-img :src="image(this.navItem.phase2.title,'png')" width="60%"></v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- <v-card height="100%" width="100%" flat tile class="">
                <v-card-text class="px-md-0">
                    <v-row no-gutters>
                        <v-col cols="1"></v-col>
                    </v-row>
                </v-card-text>
            </v-card> -->

            <!-- <v-card id="content-section" height="100%" width="100%" flat tile class="">
                <v-card-text class="px-md-0">
                    <v-row no-gutters>
                        <v-col cols="1"></v-col>
                        <v-col cols="1" class="d-flex justify-center pt-md-10">
                            <v-card height="55%" width="60%" color="grey darken-3" flat tile class="mt-md-12"></v-card>
                        </v-col>
                        <v-col>
                            <v-row no-gutters>
                                <v-col cols="4" class="offset-md-1 d-flex align-end">
                                    <div>
                                        <div class="display-2 secondary--text font-weight-bold text-uppercase">
                                            <span class="nova-font">Gestión de inventario</span>
                                        </div>
                                        <div class="title">
                                            <div class="nova-font">
                                                <span class="secondary--text">-</span> Catálogo de productos.  <br>
                                                <span class="secondary--text">-</span> Organización por categoría.  <br>
                                                <span class="secondary--text">-</span> Control de entradas y salidas.  <br>
                                                <span class="secondary--text">-</span> Reubicaciones.  <br>
                                                <span class="secondary--text">-</span> Activos fijos.  <br>
                                                <span class="secondary--text">-</span> Múltiples Almacenes * <br>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="7">
                                    <v-img src="@/assets/1-2.png" class="mb-md-6"></v-img>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="8" class="display-4 offset-md-1 text-right grey--text text--lighten-1 text-uppercase">
                                    <h1 class="nova-font" :class="{'line-height-10rem': ['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)}">¿Por qué EON?</h1>
                                </v-col>
                            </v-row>
                            <v-row align="end">
                                <v-col md="3" class="mb-md-12 mt-md-n12 offset-md-1">
                                    <div class="display-2 secondary--text font-weight-bold text-uppercase mb-md-2">
                                        <span class="nova-font">Control de acceso</span>
                                    </div>
                                    <div class="title">
                                        <div class="nova-font">
                                            <div class="mb-md-4"><span class="secondary--text font-weight-bold">-</span> <b>Registro de visitantes.</b></div>
                                            <div class="mb-md-4"><span class="secondary--text font-weight-bold">-</span> Bitácora de <b>Unidades / Transportes.</b></div>
                                            <div class="mb-md-4"><span class="secondary--text font-weight-bold">-</span> Información acorde a los lineamientos de: <b>IMMEX para RFE.</b> <span class="secondary--text">*</span></div>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col md="3" class="text-right offset-md-2">
                                    <div class="display-2 secondary--text font-weight-bold text-uppercase mb-md-2">
                                        <span class="nova-font">Ubicación de tus productos</span>
                                    </div>
                                    <div class="title">
                                        <div class="nova-font">
                                            <div class="mb-md-4"><span class="secondary--text font-weight-bold">-</span> Inventario de ubicaciones.</div>
                                            <div class="mb-md-4"><span class="secondary--text">-</span> Disponibilidad de <b>producto por ubicación.</b></div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="3" class="mb-md-12 mt-md-n6 offset-md-1">
                                    <div class="display-2 secondary--text font-weight-bold text-uppercase mb-md-2">
                                        <span class="nova-font">Fulfillment</span>
                                    </div>
                                    <div class="title">
                                        <div class="nova-font">
                                            <div class="mb-md-4"><span class="secondary--text font-weight-bold">-</span> Integración vía <b>API</b> con tu <b>ecommerce, marketplace</b> o <b>sistema de pedidos. </b></div>
                                            <div class="mb-md-4"><span class="secondary--text font-weight-bold">-</span> Surtido de pedidos con <b>políticas FIFO.</b> </div>
                                            <div class="mb-md-4"><span class="secondary--text font-weight-bold">-</span> Gestión en tiempo real de tus pedidos. </div>
                                            <div class="mb-md-4"><span class="secondary--text font-weight-bold">-</span> Integración directa con la paquetería de tu preferencia. </div>
                                            <div class="mb-md-4"><span class="secondary--text font-weight-bold">-</span> <b>Generación y seguimiento</b> de guías de rastreo.</div>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col md="3" class="text-right offset-md-2">
                                    <div class="display-2 secondary--text font-weight-bold text-uppercase mb-md-2">
                                        <div class="display-1 font-weight-bold">
                                            <span class="nova-font">Administración</span>
                                        </div>
                                        <div>
                                            <span class="nova-font">RFE</span>
                                        </div>
                                    </div>
                                    <div class="title">
                                        <div class="nova-font">
                                            <div class="mb-md-8"><span class="secondary--text font-weight-bold">-</span> Registro de pedimento para importaciones y exportaciones <b>(Entradas y salidas)</b>. </div>
                                            <div class="mb-md-8"><span class="secondary--text font-weight-bold">-</span> Catálogo de proveedores de <b>Origen y Agentes Aduanales.</b> </div>
                                            <div class="mb-md-8"><span class="secondary--text font-weight-bold">-</span> Módulos especializados para el control de importaciones temporales de <b>SECIIT.</b> </div>
                                            <div class="mb-md-8"><span class="secondary--text font-weight-bold">-</span> Cumplimos con los requerimientos del <b>Anexo 24 del IMMEX.</b></div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card> -->
            <!-- <v-card height="100%" width="100%" flat tile class="mt-md-n12">
                <v-card-text class="px-md-0 mt-md-n12">
                    <v-row no-gutters class="mt-md-n12">
                        <v-col cols="1" class="black"></v-col>
                        <v-col cols="1" class="black"></v-col>
                        <v-col>
                            <v-row>
                                <v-col cols="1" class="black" v-show="['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)"></v-col>
                                <v-col md="5" class=" black pr-md-12">
                                    <div class="headline secondary--text">
                                        <span class="nova-font"><span class="white--text headline">⟶</span> Integra fácilmente la administración de tus inventarios con tecnología RFID para garantizar mayor precisión en tu operación</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card> -->
            <!-- <v-card width="100%" flat tile>
                <v-card-text class="px-md-0">
                    <v-row justify="center">
                        <v-col md="11">
                            <v-img src="@/assets/infografia.png"></v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card> -->

            <v-card class="py-md-12">
                <v-card-text class="py-md-12">
                    <v-row justify="center" class="py-md-12">
                        <v-col cols="12" md="6" class="text-center">
                            <div class="display-1">
                                <span class="nova-font" v-html="this.navItem.phase3.text">Nuestra solución es <b>adaptable a tu negocio</b>, podemos personalizar módulos de acuerdo a tus necesidades o <b>generar complementos para garantizar la cobertura al completo de tus procesos.</b></span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card>
                <v-card-text class="pa-md-0">
                    <v-row no-gutters>
                        <v-col cols="12" md="9">
                            <v-card flat tile>
                                <v-img src="@/assets/products/indexmerca/Porta1.png"></v-img>
                                <v-overlay absolute z-index="0" color="black"></v-overlay>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3" class="d-flex align-center ml-md-n12">
                            <v-card color="black" class="ml-md-n12 pa-6 d-flex align-center justify-center flex-column" flat height="60%">
                                <v-card-title>
                                    <div class="display-1 mb-3" :class="{'headline': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name), 'title' : this.$vuetify.breakpoint.name == 'lg'}">
                                        <h3 class="nova-font secondary--text font-weight-thin" v-html="this.navItem.phase4.title">Portafolio</h3>
                                    </div>
                                </v-card-title>
                                <v-card-text class="white--text py-0 font-weight-thin">
                                    <div class="title" :class="{'subtitle-1': this.$vuetify.breakpoint.name == 'lg'}">
                                        <p class="nova-font mb-0"><span class="font-weight-bold mb-0" v-html="this.navItem.phase4.text[0]">SPORT</span></p>
                                    </div>

                                </v-card-text>
                                <v-card-text class="white--text pt-0 mt-4">
                                    <v-row>
                                        <v-col cols="12" md="12" xl="8" class="py-xl-0 pr-xl-12 subtitle-1" :class="{'subtitle-2': this.$vuetify.breakpoint.name == 'lg'}">
                                            <p class="nova-font"  v-html="this.navItem.phase4.text[1]">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris a nibh tempus, semper mauris et, condimentum nisi.
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-card-text class="white--text pt-0">
                                    <v-row>
                                        <v-col cols="12" md="12" xl="8" class="py-xl-0 pr-xl-12 subtitle-1" :class="{'subtitle-2': this.$vuetify.breakpoint.name == 'lg'}">
                                            <v-btn class="font-weight-bold" color="orange" large outlined v-html="this.navItem.phase4.button"></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <footer-component></footer-component>
        </v-container>
    </v-content>
</template>
<script>
import { image, bgImage } from "@/mixins/images";
export default {
    mixins: [image, bgImage],
    computed:{
        navItem(){
            return{
                phase0:{
                    title: this.$i18n.t("indexmerca.phrase0.title"),
                    text: this.$i18n.t("indexmerca.phrase0.text"),
                },
                phase1:{
                    title: this.$i18n.t("indexmerca.phrase1.title"),
                    text: this.$i18n.t("indexmerca.phrase1.text"),
                },
                phase2:{
                    title: this.$i18n.t("indexmerca.phrase2.title"),
                    text: this.$i18n.t("indexmerca.phrase2.text"),
                },
                phase3:{
                    title: this.$i18n.t("indexmerca.phrase3.title"),
                    text: this.$i18n.t("indexmerca.phrase3.text"),
                },
                phase4:{
                    title: this.$i18n.t("indexmerca.phrase4.title"),
                    text: this.$i18n.t("indexmerca.phrase4.text"),
                    button: this.$i18n.t("indexmerca.phrase4.button"),
                }
            }
        }
    }
}
</script>
<style>
.line-height-10rem{
    line-height: 10rem;
}
</style>