<template>
    <v-container class="height-100vh pa-0" fluid>
        <v-card min-height="100%" min-width="100%" :img="'/img/code-2620118.jpg'" flat tile>    
            <v-row no-gutters class="height-100vh animate__animated animate__fadeInLeft">
            <!-- <v-row no-gutters class="height-100vh"> -->
                <v-col class="text-center pa-0" cols="12" md="6">
                    <v-card class="d-flex flex-column" color="primary" height="100%" tile>
                        <v-spacer></v-spacer>
                        <v-card-text class="text-left py-12 px-10  mx-md-12 px-md-12 white--text">
                            <v-row>
                                <v-col cols="12" md="10" lg="10" xl="8" class="py-2">
                                    <h1 class="display-1 secondary--text">
                                        <span class="nova-font font-weight-thin" v-html="this.navItem.contact.text[0]">Estás a un paso de comenzar a <span class="font-weight-bold">innovar.</span></span>
                                    </h1>
                                    <p class="font-weight-bold ma-0 subtitle-1">
                                        <span class="nova-font" v-html="this.navItem.contact.text[2]">Trabajemos juntos en tu siguiente éxito.</span>
                                    </p>
                                    <p class="font-weight-thin ma-0 subtitle-1">
                                        <span class="nova-font" v-html="this.navItem.contact.text[3]">Escríbenos, llamanos o programa una visita, te atenderemos con gusto.</span>
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="10" lg="10" xl="8" class="py-2">
                                    <h1 class="display-1 secondary--text">
                                        <span class="nova-font font-weight-bold" v-html="this.navItem.contact.address[0]">hola@intech.mx</span>
                                    </h1>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="10" lg="10" xl="8" class="py-2">
                                    <p class="font-weight-thin ma-0 subtitle-1">
                                        <span class="nova-font" v-html="this.navItem.contact.address[2]">Sierra Leona 418, Lomas de San Luis 2da Sección C.P. 78210 San Luis, S.L.P.</span>
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="10" lg="10" xl="8" class="pt-2 pb-0">
                                    <h5 class="subtitle-1">
                                        <span class="nova-font font-weight-bold" v-html="this.navItem.contact.text[4]">Contáctanos.</span>
                                    </h5>
                                    <p class="font-weight-thin subtitle-1">
                                        <span class="nova-font" v-html="this.navItem.contact.text[1]">Cuéntanos sobre tu idea y te ayudaremos a explorar la mejor forma de hacerla suceder.</span>
                                    </p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="10" lg="10" xl="8" class="py-0">
                                    <v-text-field :disabled="this.disabledData" v-model="form.name" class="py-1" color="secondary" label="Nombre" :rules="rules.contacto" dark></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="10" lg="10" xl="8" class="py-0">
                                    <v-text-field :disabled="this.disabledData" v-model="form.enterprise" class="py-1" color="secondary" label="Empresa" :rules="rules.contacto" dark></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="10" lg="10" xl="8" class="py-0">
                                    <v-text-field :disabled="this.disabledData" v-model="form.email" class="py-1" color="secondary" label="Correo" :rules="rules.contacto" dark></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="10" lg="10" xl="8" class="py-0">
                                    <v-text-field :disabled="this.disabledData" v-model="form.message" class="py-1" color="secondary" label="Mensaje" :rules="rules.contacto" dark></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="10" lg="10" xl="8" class="py-0 text-right">
                                    


                  
                      <v-hover>
                        <v-btn @click="sendForm()" tile class="btn-hover font-weight-bold"  slot-scope="{hover}" :class="`${hover? 'white--text': 'orange--text'}`"  large outlined>{{ $t('buttons.submit') }}</v-btn>
                      </v-hover>
                    


                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-spacer></v-spacer>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <footer-component :color="'transparent'" style="position: absolute; bottom: 0;"></footer-component>

        <v-snackbar
            v-model="disabledData"
            :color="colorNotification"
        >
            {{textNotification}}
            <template v-slot:action="{ attrs }">
                <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>

    </v-container>
    <!-- <Footer></Footer> -->
</template>

<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/emailjs-com@2.3.2/dist/email.min.js"></script>
<script type="text/javascript">
   (function(){
      emailjs.init("user_oTZFZCCe2z0RTmgexBOFZ");
   })();
</script>
<script>
// import Footer from '@/components/Footer.vue'
import axios from "axios";
import qs from "qs";
export default {
  name: 'Contact',
  data(){
    const defaultForm = Object.freeze({
        name: "",
        enterprise: "",
        email: "",
        message: ""
    });      
    return {
        /*form: {
            to_name: 'Emmanuel González',
            from_name: '',
            from_company: '',
            email: '',
            message_html: '',
        },*/
        form: Object.assign({}, defaultForm),
        rules: {
          contacto: [val => (val || "").length > 0 || "Campo es requerido"]
        },
        defaultForm,
        disabledData:false,
        textNotification: null,
        colorNotification:null
    }
  },
  components: {
    // Footer
  },
  mounted(){
      this.$store.state.showScrollBtn = false
      this.$store.state.appBarColor = 'transparent'
      this.$store.state.appBarDark = true
  },
  methods: {
      sendEmail(){
          emailjs.send('gmail', 'template_thzyy7aY', this.form);
      },
    sendForm: function() {
        let payload = {
            name: this.form.name,
            enterprise: this.form.enterprise,
            email: this.form.email,
            message: this.form.message
        };
        axios({
            url: "../php/mail.php",
            method: "post",
            data: qs.stringify(payload),
            headers: {
                "Content-Type": "application/form-data",
                "accept-language": "en_US",
                "content-type": "application/x-www-form-urlencoded"
            }
        })
        .then(() => {
            // your action after success
            //alert("Tu mensaje ha sido enviado");
            this.disabledData = true;
            this.textNotification = "El mensaje se a enviado exitosamente";
            this.colorNotification = "green darken-2"
        })
        .catch((error) => {
            // your action on error success
            this.disabledData = true;
            this.textNotification = error;
            this.colorNotification = "red darken-2"
        });
    }
  },
  computed:{
      navItem(){
          return{
              contact:{
                  text: this.$i18n.t("contact.text"),
                  address: this.$i18n.t("contact.address")
              }
          }
      }
  }
}
</script>

<style>
.height-100vh{
    min-height: 100vh;
}
</style>
