import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './components/'

import VueI18n from 'vue-i18n';
import messages from './lang';

Vue.config.productionTip = false
document.title = 'Yntech'

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0);
Vue.prototype.$scrollToContent = () =>  window.scrollTo(0,0);

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
