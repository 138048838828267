<template>
    <v-content>
        <v-container class="pa-0" :class="{/*'fill-height': ($store.state.offsetTop < window.innerHeight) */}" fluid>
            <v-card flat tile class="d-flex align-center flex-column" :dark="$vuetify.theme.dark">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" md="6" class="offset-md-1"><router-link :to="'/'+$i18n.locale+'/portafolio'" :class="{'white--text':$vuetify.theme.dark}">{{ $t('buttons.back') }}</router-link></v-col>
                        <v-col cols="6" md="2" xl="1">
                            <div style="border-top: 3px solid #95b200;">CLIENTE</div>
                            <div v-html="this.navItem.product.client"></div>
                        </v-col>
                        <v-col cols="6" md="2" xl="1" class="offset-xl-1">
                            <div style="border-top: 3px solid #95b200;">ROLE</div>
                            <div v-html="this.navItem.product.role"></div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-text class="pa-0 pa-md-4 pa-xl-4 mb-xl-12"></v-card-text>
                <v-card-text class="mt-xl-12">
                    <v-row align="center" no-gutters>
                        <v-col md="7" class="offset-md-1">
                            <div class="font-weight-bold " :class="{'display-4': this.$vuetify.breakpoint.name == 'xl', 'display-3': this.$vuetify.breakpoint.name != 'xl', 'display-2': this.$vuetify.breakpoint.name == 'xs', 'white--text':$vuetify.theme.dark }">
                                <span class="nova-font" v-html="this.navItem.product.title"></span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row align="center" no-gutters>
                        <v-col md="7" class="offset-md-1">
                            <div class="title-description" :class="{'subtitle-1': this.$vuetify.breakpoint.name == 'xs'}">
                                <span class="nova-font" v-html="this.navItem.product.description"></span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text>
                    <v-row align="center" no-gutters>
                        <v-col md="7" class="offset-md-1">
                            <div class="secondary--text font-weight-bold" :class="{'display-1': this.$vuetify.breakpoint.name == 'xl', 'headline': this.$vuetify.breakpoint.name != 'xl'}">
                                <span class="nova-font">Objetivo</span>
                            </div>
                            <div class="material-text" :class="{'subtitle-1': this.$vuetify.breakpoint.name == 'xs'}">
                                <span class="nova-font" v-html="this.navItem.product.objective"></span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <!-- v-card flat tile class="d-flex align-center flex-column height-100" dark color="#C4DBE9">
                <v-card-text>
                    <v-row align="center">
                        <v-col class="d-flex justify-center justify-md-end">
                            <v-card color="transparent" flat width="100%">
                                <v-img :src="image(this.portrait.name,this.portrait.extension)"></v-img>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card -->
            <div class="project-portrait" :style="bgPortrait(this.portrait.name,this.portrait.extension)">
            </div>
            <v-card flat tile class="d-flex align-center flex-column height-100" :dark="$vuetify.theme.dark">
                <v-card-text class="py-xl-10">
                    <v-row align="center" no-gutters>
                        <v-col md="7" class="offset-md-1">

                                    <div class="secondary--text font-weight-bold" :class="{'display-1': this.$vuetify.breakpoint.name == 'xl', 'headline': this.$vuetify.breakpoint.name != 'xl'}">
                                        <span class="nova-font">Concepto</span>
                                    </div>
                                    <div class="material-text" :class="{'subtitle-1': this.$vuetify.breakpoint.name == 'xs'}">
                                        <span class="nova-font" v-html="this.navItem.product.concept"></span>
                                    </div>


                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text class="py-0">
                    <v-row align="center">
                        <v-col md="7" class="offset-md-1">
                            <v-img :src="image(this.images[0].name,this.images[0].extension)" width="100%"></v-img>
                        </v-col>
                        <v-col md="7" class="offset-md-1">
                            <v-row align="center" no-gutters>
                                <v-col>
                                    



                                    <div class="secondary--text title font-weight-bold">
                                        <span class="nova-font">Marca</span>
                                    </div>
                                    <div class="material-text" :class="{'subtitle-1': this.$vuetify.breakpoint.name == 'xs'}">
                                        <span class="nova-font" v-html="this.navItem.product.trade"></span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>











            </v-card>
            <v-footer padless>
                <v-card width="100%">
                    <v-card-text class="pa-0">
                        <v-row align="center">
                            <v-col md="6" class="white d-flex pt-4 pb-4 pl-6 pr-6 subtitle-1 font-weight-bold" :class="{'caption': this.$vuetify.breakpoint.name == 'xs'}">
                                <router-link to="yego" @click.native="$scrollToTop">
                                <div class="secondary--text nova-font text-uppercase">
                                    ← ERP Yego
                                </div>
                                </router-link>
                            </v-col>
                            <v-col md="6" class="secondary d-flex flex-column flex-sm-row pt-4 pb-4 pl-6 pr-6 subtitle-1 font-weight-bold flexbox-right flexbox-right" :class="{'caption': this.$vuetify.breakpoint.name == 'xs'}">
                                <router-link to="chronos" @click.native="$scrollToTop">
                                    <div class="white--text nova-font text-uppercase">
                                        Chronos WMS →
                                    </div>
                                </router-link>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-footer>
        </v-container>
    </v-content>
</template>
<script>
import { image, bgImage } from "@/mixins/images";

export default {
    mixins: [image, bgImage],
    data(){
        return{
            portrait:{
                name:"projects/meta/Meta",
                extension:"png"
            },
            images:[
                {
                name:"projects/meta/2",
                extension:"png"                   
                },
                {
                name:"projects/meta/2",
                extension:"png"                   
                }
            ]
        }
    },    
    computed:{
        navItem(){
            return{
                product:{
                    title: this.$i18n.t("proyect.meta.title"),
                    client: this.$i18n.t("proyect.meta.client"),
                    role: this.$i18n.t("proyect.meta.role"),
                    description: this.$i18n.t("proyect.meta.description"),
                    objective: this.$i18n.t("proyect.meta.objective"),
                    concept: this.$i18n.t("proyect.meta.concept"),
                    trade: this.$i18n.t("proyect.meta.trade")
                }
            }
        }
    }
}
</script>