import Home from '../views/Home.vue'
import Portfolio from '../views/Portfolio.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'

const routesnav = [
    {
        path: 'inicio',
        name: 'Home',
        component: Home
    },
    {
        path: 'portafolio',
        name: 'Portfolio',
        component: Portfolio
    },
    {
        path: 'acerca-de',
        name: 'About',
        component: About
    },
    {
        path: 'contacto',
        name: 'Contact',
        component: Contact
    },    
];

export default routesnav