import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { i18n } from "./../main";

import Lang from '../components/RouterView.vue'

import projects from './projects'
import products from './products'
import routesnav from './routesnav'

import Error404 from '../views/404.vue'

Vue.use(VueRouter)

  const routes: Array<RouteConfig> = [
    {
		path: "*",
		name: "Error404",
		//component: () => import("../views/404.vue")
		component: Error404
	},  
	{
		path: '/',
		redirect: '/es/inicio'
	},
	{
		path: '/:lang',
		name: 'Lang',
		component: Lang,
		beforeEnter(to, from, next) {
		const lang = to.params.lang;
		if (!["en", "es"].includes(lang)) return next("es/inicio");
			if (i18n.locale !== lang) {
				i18n.locale = lang;
		}
			return next();
		},
		children: [
		... routesnav,
		... projects,
		... products,
		]
	}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  //base: '/',
  routes
})

export default router
