<template>
  <div>
      <v-card width="100%" class="cookies-alert" color="gryntech" dark>
        <v-system-bar window color="gryntech">
          <v-spacer></v-spacer>
          <v-btn icon small @click="cookiesAgreement"><v-icon>close</v-icon></v-btn>
        </v-system-bar>
        <v-card-text>
          <v-row no-gutters>
            <v-col class="text-center headline">
              <p class="nova-font">
                Este sitio usa cookies. <br>
                Para continuar usando este sitio se debe aceptar nuestro uso de cookies
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="text-center">
              <v-btn text x-large class="font-weight-bold">Ver más</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
export default {
  mounted(){
    this.$emit('hideAlert')
  },
  methods: {
    cookiesAgreement(){
      const date = new Date()
      date.setMonth(date.getMonth() + 1)
      document.cookie = "agreement="+true+"; expires="+date
      this.$emit('hideAlert')
    }
  }
}
</script>

<style>
.cookies-alert{
  position: fixed;
  bottom: 0;
  z-index: 5;
}
</style>