import ProductEon from '../views/Products/Eon.vue'
import ProductIndexmerca from '../views/Products/Indexmerca.vue'
import ProductChronos from '../views/Products/Chronos.vue'

const products = [
	{
		path: 'productos/eon-wms',
		name: 'Product-Eon',
		component: ProductEon
	},
	{
		path: 'productos/indexmerca',
		name: 'Product-Indexmerca',
		component: ProductIndexmerca
	},
	{
		path: 'productos/chronos',
		name: 'Product-Indexmerca',
		component: ProductChronos
	}
  ]

export default products