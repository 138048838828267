<template>
  <v-content>
    <v-container class="pa-0" :class="{/*'fill-height': ($store.state.offsetTop < window.innerHeight) */}" fluid>
      <v-card flat tile class="d-flex align-center flex-column height-100">
        <v-spacer></v-spacer>
        <v-card-text>
          <v-row justify="center" align="center">
            <v-col class="text-center">              
              <h1 class="display-3"><span class="font-weight-thin nova-font">YN</span><span class="font-weight-bold nova-font">TECH</span></h1>
              <h2 class="display-1" :class="{'headline': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)}"><span class="nova-font font-weight-thin secondary--text">Your New Technology</span></h2>
              <div class="display-2" :class="{'headline': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)}">
                <p class="nova-font font-weight-thin greytxt--text">
                    {{ $t('home.banner.pt1') }} <br>
                    {{ $t('home.banner.pt2') }} <br v-if="$i18n.locale === 'es'">
                    {{ $t('home.banner.pt3') }} <span class="secondary--text font-weight-bold">{{ $t('home.banner.accent') }}</span> {{ $t('home.banner.pt4') }}
                    
                </p>
              </div>
            </v-col>            
          </v-row>
          <v-row justify="center" align="center">
            <v-col class="text-center">
              <router-link @click.native="$scrollToTop" to="contacto">
                <v-hover>
                <v-btn tile class="btn-hover font-weight-bold"  slot-scope="{hover}" :class="`${hover? 'white--text': 'orange--text'}`" large outlined>{{ $t('buttons.work') }}</v-btn>
                </v-hover>
              </router-link>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
      </v-card>
      <v-scroll-x-transition>
        <v-card id="content-section" height="100%" width="100%" flat tile class="d-flex align-center justify-center flex-column">
          <v-card-text class="pr-0">
            <v-row no-gutters align="center">
              <v-col cols="12" md="5" xl="5">
                <v-row>
                  <v-col cols="12" md="2" lg="2" xl="6" class="d-flex" :class="{'align-start justify-start': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name), 'align-end justify-end': ['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)}">
                    <div class="mb-0 pb-0 title" >
                      <img :src="image($t('home.contentSection1.softwareDevelopment.title_image'),'png')" :width="$t('home.contentSection1.softwareDevelopment.title_size')"/> 
                    </div>
                  </v-col>
                  <v-col cols="12" md="10" lg="10" xl="6" class="pl-3 pr-6 py-0">
                    <div class="mb-4 d-flex align-center greytxt--text">
                      <h2>01</h2>
                      <div class="middle-border"></div>
                    </div>
                    <div class="headline greytxt--text" :class="{'title': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)}">
                      <p class="nova-font">
                        <span class="font-weight-bold mb-4">
                          {{ $t('home.contentSection1.softwareDevelopment.contentP1') }}
                        </span>
                        {{ $t('home.contentSection1.softwareDevelopment.contentP2') }} <span class="font-weight-bold">{{ $t('home.contentSection1.softwareDevelopment.contentP3') }}</span> {{ $t('home.contentSection1.softwareDevelopment.contentP4') }}
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="12" lg="4" xl="6" class="offset-md-1 offset-xl-4 pl-md-2 pl-xl-8 pa-0">
                    <router-link @click.native="$scrollToTop" to="contacto">
                      <v-hover>
                      <v-btn  tile class="ml-10 btn-hover font-weight-bold"  slot-scope="{hover}" :class="`${hover? 'white--text': 'orange--text'}`"  large outlined>{{ $t('buttons.work') }}</v-btn>
                      </v-hover>
                    </router-link>
                  </v-col>

                </v-row>
                <v-row align="center">
                </v-row>
              </v-col>
              <v-col class="pr-0" cols="12" md="7" xl="7">
                <v-card flat height="100%" class="d-flex justify-center">
                  <v-img src="@/assets/img/Mock Up 2.png" contain max-width="100%"></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="py-0">
            <v-row no-gutters>
              <v-col cols="2" class="offset-xl-1 py-0">
                <v-img v-if="!$store.state.appBarDark" src="@/assets/5.png" width="65%"></v-img>
                <v-img v-else src="@/assets/2.png" width="65%"></v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-scroll-x-transition>

      <v-scroll-x-transition>
        <v-card id="infrastructure" height="100%" width="100%" flat tile class="d-flex align-center justify-center flex-column">
          <v-card-text class="px-md-0">
            <v-row no-gutters align="center" :class="{'flex-column-reverse': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)}">
              <v-col cols="12" md="6">
                <v-card flat height="100%" class="d-flex justify-center">
                  <v-img src="@/assets/img/1510336939933.jpeg--.png" contain max-width="100%"></v-img>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-row justify="start" :class="{'flex-column-reverse': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)}">
                  <v-col cols="12" md="10" lg="9" class="pl-md-12 pr-md-0 py-0">
                    <v-row>
                      <v-col cols="12" md="9" class="pl-md-4 pr-md-0 offset-md-2">
                        <div class="mb-4 d-flex align-center greytxt--text">
                          <h2>02</h2>
                          <div class="middle-border"></div>
                        </div>
                        <div class="headline greytxt--text" :class="{'title': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)}">
                          <p class="nova-font text-right mb-1">
                            {{ $t('home.contentSection1.infrastructure.contentP1') }}
                            <span class="font-weight-bold">
                              {{ $t('home.contentSection1.infrastructure.contentP2') }}
                            </span>
                            {{ $t('home.contentSection1.infrastructure.contentP3') }}
                            <span class="font-weight-bold">
                              {{ $t('home.contentSection1.infrastructure.contentP4') }}
                            </span>
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="2" class="px-md-0 d-flex align-start">
                    <div class="headline" :class="{'title': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)}">
                      <img :src="image($t('home.contentSection1.infrastructure.title_image'),'png')" :width="$t('home.contentSection1.infrastructure.title_size')" />
                    </div>
                  </v-col>

                  <v-col cols="12" md="8" class="offset-xl-2 offset-lg-2 text-right py-md-0 px-md-12">
                    <router-link @click.native="$scrollToTop" to="contacto">
                      <v-hover>
                        <v-btn   tile class="btn-hover font-weight-bold"  slot-scope="{hover}" :class="`${hover? 'white--text': 'orange--text'}`"  large outlined>{{ $t('cta.communicate') }}</v-btn>
                      </v-hover>
                    </router-link>
                  </v-col>

                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="py-0 mt-lg-n12 mt-xl-n12">
            <v-row no-gutters class="mt-lg-n8 mt-xl-n12">
              <v-spacer></v-spacer>
              <v-col cols="2" class="px-12 d-flex justify-center mt-xl-n11">
                <v-img v-if="!$store.state.appBarDark" src="@/assets/4.png"></v-img>
                <v-img v-else src="@/assets/1.png"></v-img>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
        </v-card>
      </v-scroll-x-transition>

      <v-scroll-x-transition>
        <v-card id="ux" height="100%" width="100%" flat tile class="d-flex align-center justify-center flex-column">
          <v-card-text class="pr-md-0">
            <v-row no-gutters align="center">
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" md="2" xl="5" class="d-flex" :class="{'align-start justify-start': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name), 'align-end justify-end': ['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)}">
                    <div class="display-1">
                      <img :src="image($t('home.contentSection1.ux.title_image'),'png')" :width="$t('home.contentSection1.ux.title_size')"/>
                    </div>
                  </v-col>
                  <v-col cols="12" md="8" xl="5" class="px-md-2 py-0">
                    <div class="mb-md-4 d-flex align-center greytxt--text">
                      <h2>03</h2>
                      <div class="middle-border"></div>
                    </div>
                    <div class="headline greytxt--text" :class="{'title': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)}">
                      <p class="nova-font" :class="{'mb-0': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)}">
                        <span class="font-weight-bold">
                          {{ $t('home.contentSection1.ux.contentP1') }}
                        </span>
                          {{ $t('home.contentSection1.ux.contentP2') }}
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="12" md="11" lg="12" xl="6" class="offset-lg-4 offset-xl-4 px-lg-0 py-md-0 px-xl-7">
                    <router-link @click.native="$scrollToTop" to="contacto">
                      <v-hover>
                        <v-btn   tile class="btn-hover font-weight-bold"  slot-scope="{hover}" :class="`${hover? 'white--text': 'orange--text'}`"  large outlined>{{ $t('cta.wwu') }}</v-btn>
                      </v-hover>
                    </router-link>
                  </v-col>

                </v-row>
                <v-row justify="center" align="center">

                </v-row>
              </v-col>
              <v-col class="pr-0" cols="12" md="6">
                <v-card flat height="100%" class="d-flex justify-center">
                  <v-img src="@/assets/img/photo-1531403009284-440f080d1e12.png" contain max-width="100%"></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-scroll-x-transition>
     
      <v-card id="quote" flat tile class="d-flex align-center justify-center flex-column py-12">
        <v-card-text class="py-md-12">
          <v-row justify="center" align="center">
            <v-col cols="12" md="8">
              <p class="display-2 text-center" :class="{'display-1': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name)}">
                  <span class="nova-font">{{ $t('home.quoteSection.contentP1') }} </span>
                    <span class="secondary--text font-weight-bold">
                      <span class="nova-font">{{word}}</span>
                    </span><br>
                  <span class="nova-font"> {{ $t('home.quoteSection.contentP3') }}</span>
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card> 

      <!-- v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="11">
              <div class="display-1">
                <p class="nova-font font-weight-bold secondary--text">
                  {{ $t('home.ourProducts.title') }}
                </p>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" sm="8" md="5" class="pr-md-10">
              <v-card flat>
                <v-card-text class="pa-0">
                  <img src="@/assets/img/Mock 5.png" width="100%" alt="">
                </v-card-text>
                <v-card-text class="px-md-12 py-6 margin-top--20">
                  <v-card color="black" class="px-md-4">
                    <v-card-title class="secondary--text">
                      <div>
                        <h3 class="font-weight-thin">{{ $t('home.ourProducts.products.eon.title.type') }}</h3>
                        <h1 class="font-weight-bold">{{ $t('home.ourProducts.products.eon.title.name') }}</h1>
                      </div>
                    </v-card-title>
                    <v-card-text class="white--text">
                      <v-row>
                        <v-col class="py-0 subtitle-2">
                          <p class="nova-font">
                            <span class="font-weight-bold">
                              {{ $t('home.ourProducts.products.eon.subtitle') }}
                            </span><br>
                            {{ $t('home.ourProducts.products.eon.description') }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text-center py-1 mt-2">
                          <v-hover>
                            <v-btn @click.native="$scrollToTop" tile class="btn-hover font-weight-bold"  slot-scope="{hover}" :class="`${hover? 'white--text': 'orange--text'}`" large outlined :to="'/'+$i18n.locale+'/productos/eon-wms'">{{ $t('cta.communicate') }}</v-btn>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="8" md="5" class="pl-md-10">
              <v-card flat>
                <v-card-text class="pa-0">
                  <img src="@/assets/img/Mock 4.png" width="100%" alt="">
                </v-card-text>
                <v-card-text class="px-md-12 py-6 margin-top--20">
                  <v-card color="black" class="px-md-4">
                    <v-card-title class="secondary--text">
                      <div>
                        <h3 class="font-weight-thin">{{ $t('home.ourProducts.products.indexmerca.title.type') }}</h3>
                        <h1 class="font-weight-bold">{{ $t('home.ourProducts.products.indexmerca.title.name') }}</h1>
                      </div>
                    </v-card-title>
                    <v-card-text class="white--text">
                      <v-row>
                        <v-col class="py-0 subtitle-1">
                          <p class="nova-font">
                            {{ $t('home.ourProducts.products.indexmerca.description') }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="text-center py-1">
                          <v-hover>
                            <v-btn @click.native="$scrollToTop" tile class="btn-hover font-weight-bold"  slot-scope="{hover}" :class="`${hover? 'white--text': 'orange--text'}`"  large outlined :to="'/'+$i18n.locale+'/productos/indexmerca'">{{ $t('cta.communicate') }}</v-btn>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card -->





      <v-card class="pr-lg-12 px-md-12" flat>
        <v-card-text class="px-lg-12">
<v-row>
<v-col>
                  <div class="display-1 mb-3" :class="{'headline': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name), 'title' : this.$vuetify.breakpoint.name == 'lg'}">
                    <h3 class="nova-font secondary--text">Portafolio </h3> 
                  </div>
</v-col>
</v-row>

        </v-card-text>
      </v-card>


  <v-carousel v-model="model" height="auto">
    <v-carousel-item
      v-for="(slide,index) in this.slider"
      :key="index"
      
    >
      <v-sheet
        height="100%"
        tile
        
      >


      <v-card class="pr-lg-12 px-md-12 black--color" flat>
        <v-card-text class="px-lg-12">

          <v-row align="end" class="pb-12 pr-lg-12">
            <v-col cols="12" lg="10" class="pl-lg-6 pr-lg-12">
              <v-card flat>

                <v-img :src="image(slide.bg.name,slide.bg.extension)"></v-img>
                <v-overlay absolute z-index="0" color="black">
                  <div class="text-center">
                    <img :src="image(slide.icon.name,slide.icon.extension)" :style="`width:${slide.icon.size}`" />
                  </div>
                </v-overlay>
                
              </v-card>
            </v-col>
          </v-row>
          <v-row justify-md="end" class="pb-12">
            <v-col cols="12" md="8" lg="5" class="margin-top--30 mr-xl-6 px-xl-7 px-md-0 pt-md-12">
              <v-card dark class="py-6 mb-12 mr-lg-12 black--color" flat>

                <v-card-text class="white--text pt-0">
                  <v-row>
                    <v-col cols="12" md="12" xl="8" class="py-xl-0 pr-xl-12 subtitle-1" :class="{'subtitle-2': $vuetify.breakpoint.name == 'lg'}">
                      <p class="nova-font block-black" v-html="slide.text.description">
                        
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-text class="white--text py-0 font-weight-thin">
                  <div class="subtitle-2" :class="{'body-1': $vuetify.breakpoint.name == 'lg'}">
                    <router-link :to="slide.link" @click.native="$scrollToTop">
                      <v-hover>
                        <v-btn tile class="btn-hover font-weight-bold"  slot-scope="{hover}" :class="`${hover? 'white--text': 'orange--text'}`"  large outlined>{{ $t('buttons.see') }}</v-btn>
                      </v-hover>
                    </router-link>
                  </div>
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>



        </v-card-text>
      </v-card>



      </v-sheet>
    </v-carousel-item>
  </v-carousel>


      <footer-component></footer-component>
    </v-container>
  </v-content>
</template>

<script>
// import HelloWorld from '@/components/HelloWorld.vue'
import { image, bgImage } from "@/mixins/images";
export default {
  mixins: [image, bgImage],
  name: 'Home',
  computed: {
    window: function(){
      return window;
    },
    navItem(){
      return{
        portfolio:{
          background:{
            name: this.$i18n.t("home.portfolio.background.name"),
            extension: this.$i18n.t("home.portfolio.background.extension")          
          },
          image:{
            name: this.$i18n.t("home.portfolio.image.name"),
            extension: this.$i18n.t("home.portfolio.image.extension")
          },
          description:{
            title:"",
            text: this.$i18n.t("home.portfolio.description.text"),
            image:{
              name:this.$i18n.t("home.portfolio.description.image.name"),
              extension:this.$i18n.t("home.portfolio.description.image.extension")
            }
          }
        }
      }
    },
    slider(){
      return[
        {
          bg:{
            name:"portada-chronos-portfolio",
            extension:"jpg"
          },
          icon:{
            name:"logos/chronos",
            extension:"png",
            size:"60%"
          },
          link: "proyectos/chronos",
          text:{
            title:"",
            description:"Creamos un Sistema de Control de Almacenes (WMS) con todas las funciones necesarias para administrar la ejecución de un almacén de cualquier tamaño"
          }
        },
        {
          bg:{
            name:"projects/indexmerca/1",
            extension:"png"
          },
          icon:{
            name:"logos/logotipo-indexmerca-blanco",
            extension:"png",
            size:"50%"
          },
          link: "proyectos/indexmerca",
          text:{
            title:"",
            description:"Creamos de la mano con Index un ERP especializado para la evaluación de servicios, permite gestionar eficientemente los recursos necesarios para operar un ecosistema de mystery shopping a gran escala."
          }
        },
        {
          bg:{
            name:"projects/sacar/1023",
            extension:"jpeg"
          },
          icon:{
            name:"logos/sacar",
            extension:"png",
            size:"100%"
          },
          link: "proyectos/sacar",
          text:{
            title:"",
            description:"Desarrollamos un sistema ERP para la empresa con módulos para auxiliar en la gestión del proceso de ventas de manera integral, con herramientas que trabajan de forma sinérgica para brindar a SACAR un panorama claro sobre el estado de cada transacción, así como trazabilidad y certeza de su información en todos los niveles."
          }
        }
      ]
    },
    arrayTimer(){
      return{
        at : this.$i18n.t("home.quoteSection.contentP2")
      }
    } 
  },
  mounted(){
    this.$store.state.appBarColor = 'white'
    this.$store.state.showScrollBtn = true
    this.$store.state.appBarDark = false
    // this.$vuetify.theme.dark = false
    this.updateWord();
    setInterval(this.updateWord, 1200);
    window.addEventListener('scroll', this.changeScroll);
  },
  data: () => ({
    show: false,
    offsetTop: 0,
    word: "",
      model: 0,
      colors: [
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange',
      ]
  }),
  methods: {
    onScrollxd (e) {
      this.offsetTop = e.target.scrollTop
    },
    showContent(id){
      // return 'stateOffsetTop '+this.$store.state.offsetTop+' resta '+ (this.window.innerHeight - document.getElementById(id).offsetHeight)
      if(id != null){
        const element = document.getElementById(id)
        if(element != null)
          return this.$store.state.offsetTop >= (this.window.innerHeight - document.getElementById(id).offsetHeight)
        else return false
      }else return false
    },
    updateWord: function() {
      this.word = this.arrayTimer.at[Math.floor(Math.random() * this.arrayTimer.at.length)];
    },

     changeScroll(){
        const y = window.scrollY
        if ( y > 50){
            this.$store.state.appBarColor = 'white'
            this.$store.state.showScrollBtn = true
            this.$store.state.appBarDark = false
            // this.$vuetify.theme.dark = false
        }
        else{
            this.$store.state.appBarColor = 'white'
            this.$store.state.showScrollBtn = true
            this.$store.state.appBarDark = false
            // this.$vuetify.theme.dark = false
        }

     } 

  }
  // components: {
  //   HelloWorld
  // }
}
</script>

<style>
.title-rotate-270{
  transform: matrix(-0.00,-1.00,1.00,-0.00,0,0);
  -ms-transform: matrix(-0.00,-1.00,1.00,-0.00,0,0);
  -webkit-transform: matrix(-0.00,-1.00,1.00,-0.00,0,0);
}
.title-rotate-none{
  transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  transform-origin: unset !important;
}
.title-rotate-90{
  transform: matrix(0.00,1.00,-1.00,0.00,0,0);
  -ms-transform: matrix(0.00,1.00,-1.00,0.00,0,0);
  -webkit-transform: matrix(0.00,1.00,-1.00,0.00,0,0);
}
.title-desarrollo{
  transform-origin: 65% -145%;
}
.title-infraestructura{
  transform-origin: 2% 75%;
}
.title-infraestructura-xl {
    transform-origin: 20% -120%!important;
}
.title-ux{
  transform-origin: 80% 50%;
}
.middle-border{
  margin-left: 8%;
  border-top: solid 1px #444444;
  width: 100%;
}
.middle-border::before{
  content: "";
}
.margin-top--10{
  margin-top: -10%;
}
.margin-top--20{
  margin-top: -20%;
}
.margin-top--25{
  margin-top: -25%;
}
.margin-top--30{
  margin-top: -30%;
}
.block-black{
    background-color: rgba(0,0,0,0.5);
    padding: 25px 60px 25px 60px;
    min-width: 450px;  
}
.no-black{
    background-color: rgba(0,0,0,0.5);
    padding: 25px 60px 25px 60px;
    min-width: 450px;  
}
</style>
