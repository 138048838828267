<template>
    <v-content>
        <v-container class="pa-0" :class="{/*'fill-height': ($store.state.offsetTop < window.innerHeight) */}" fluid>
            <v-card flat tile class="d-flex align-center flex-column height-100">
                <v-spacer class="d-none d-md-flex"></v-spacer>
                <v-card-text>
                    <v-row :class="{'justify-center align-center' : this.$vuetify.breakpoint.name == 'xs'}">
                        <v-col cols="11" md="8" lg="10" xl="7" class="offset-md-1 offset-xl-1 display-2 secondary--text" :class="{'display-1' : this.$vuetify.breakpoint.name == 'xs'}">
                            <div class="nova-font mx-md-8 px-md-12 px-lg-12 mx-xl-6 px-xl-12" >
                                <span v-html="this.navItem.title[0]"></span>
                                <span class="display-3" :class="{'display-1' : this.$vuetify.breakpoint.name == 'xs'}">
                                    <span class="font-weight-bold nova-font" v-html="this.navItem.title[1]">pasión por la tecnología</span>
                                </span>
                            </div>
                        </v-col>            
                    </v-row>
                    <v-row justify="center" align="center">
                        <v-col cols="1" sm="1" md="1" lg="2" xl="1" class="text-center d-none d-sm-flex">
                            <v-img src="@/assets/2.png" width="100%" alt=""></v-img>
                        </v-col>
                        <v-col cols="11" md="9" xl="9" class="text-left headline ml-md-n12 pl-md-12 pr-md-12 greytxt--text" :class="{'subtitle-1' : this.$vuetify.breakpoint.name == 'xs'}">
                            <div class="nova-font pr-md-8 px-xl-8" v-html="this.navItem.text1"></div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
            </v-card>

            <v-card id="content-section" flat tile class="d-flex align-center flex-column height-100">
                <v-spacer class="d-none d-md-flex"></v-spacer>
                <v-card-text class="px-0">
                    <v-row align="center">
                        <v-col cols="12" lg="7" class="px-0">
                            <v-img src="@/assets/img/office-1209640.png" width="100%" alt=""></v-img>
                        </v-col>
                        <v-col cols="12" lg="5" xl="3" class="offset-xl-1 pr-xl-12">
                            <v-row>
                                <v-col class="text-right px-lg-12">
                                    <div class="title secondary--text" :class="{'subtitle-1' : ['lg', 'xl'].includes(this.$vuetify.breakpoint.name), 'subtitle-1 text-center px-4': this.$vuetify.breakpoint.name == 'xs'}">
                                        <h1 class="nova-font" v-html="this.navItem.title1"></h1>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-right px-lg-12">
                                    <div class="display-1 pl-lg-2 greytxt--text" :class="{'title' : ['lg', 'xl'].includes(this.$vuetify.breakpoint.name), 'headline text-center px-4': this.$vuetify.breakpoint.name == 'xs'}">
                                        <p class="nova-font pb-0" v-html="this.navItem.text2"></p>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-right px-lg-12 py-0">
                                    <v-hover>
                                        <v-btn tile class="btn-hover font-weight-bold"  slot-scope="{hover}" :class="`${hover? 'white--text': 'orange--text'}`" outlined>
                                            <span class="nova-font"> {{$t("buttons.contact")}} </span>
                                        </v-btn>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
            </v-card>

            <v-card color="#0D0D0D">
                <!-- div>
                <v-carousel v-model="slider" next-icon="arrow_forward" prev-icon="arrow_back" height="100%" hide-delimiters cycle>
                    <v-carousel-item class="px-12 px-md-12">
                       
                        <v-row class="fill-height px-md-12" align="center" justify="center">
                            <div>
                                <v-row justify="center">
                                    <v-col class="text-center secondary--text nova-font display-1 text-uppercase">
                                        <div class="nova-font">Nuestros clientes</div>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" class="headline">
                                    <v-col cols="10" lg="7" class="text-center white--text nova-font">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.. Class aptent taciti sociosqu ad litora torquent per conubia nostra.
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col class="text-center secondary--text nova-font">
                                        - JOHN DOE, SPORT LOGO
                                    </v-col>
                                </v-row>
                            </div>
                        </v-row>
                        <
                    </v-carousel-item>
                    <v-carousel-item class="px-12 px-md-12">
                        
                        <v-row class="fill-height px-md-12" align="center" justify="center">
                            <div>
                                <v-row justify="center">
                                    <v-col class="text-center secondary--text nova-font display-1 text-uppercase">
                                        <div class="nova-font">Nuestros clientes</div>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" class="headline">
                                    <v-col cols="10" lg="7" class="text-center white--text nova-font">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.. Class aptent taciti sociosqu ad litora torquent per conubia nostra.
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col class="text-center secondary--text nova-font">
                                        - JOHN DOE, SPORT LOGO
                                    </v-col>
                                </v-row>
                            </div>
                        </v-row>
                        
                    </v-carousel-item>
                </v-carousel>
                </div -->

                <!-- <v-card-text>
                    <v-row align="center">
                        <v-col cols="1"><v-icon left dark x-large>keyboard_backspace</v-icon></v-col>
                        <v-col class="white--text">
                            <v-row justify="center">
                                <v-col class="text-center">
                                    Nuestros clientes
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col class="text-center">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.. Class aptent taciti sociosqu ad litora torquent per conubia nostra.
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col class="text-center">
                                    - JOHN DOE, SPORT LOGO
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="1" class="text-right"><v-icon dark right x-large style="transform: rotate(-180deg);">keyboard_backspace</v-icon></v-col>
                    </v-row>
                </v-card-text> -->
            </v-card>

            <v-card flat tile class="d-flex align-center flex-column height-100">
                <v-spacer class="d-none d-md-flex"></v-spacer>
                <v-card-text class="px-md-0">
                    <v-row align="center" :class="{'justify-center': this.$vuetify.breakpoint.name == 'xs'}">
                        <v-col cols="11" lg="4" xl="3" class="offset-lg-1 offset-xl-2 pl-xl-1">
                            <v-row class="pl-xl-12" justify-xl="end">
                                <v-col xl="10" class="pr-xl-3 pl-xl-6">
                                    <div class="display-1 greytxt--text" :class="{'title' : ['lg', 'xl'].includes(this.$vuetify.breakpoint.name), 'headline' : this.$vuetify.breakpoint.name == 'xs'}">
                                        <p class="nova-font mb-0" v-html="this.navItem.text30">
                                            Somos parte de <span class="font-weight-bold">GrupoMerca</span> junto a nuestras empresas hermanas podemos ayudarte a realizar
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="pl-xl-12" justify-xl="end">
                                <v-col xl="10" class="pl-xl-6 py-1">
                                    <div class="title secondary--text" :class="{'subtitle-1' : ['lg', 'xl'].includes(this.$vuetify.breakpoint.name), 'subtitle-1 ': this.$vuetify.breakpoint.name == 'xs'}">
                                        <h1 class="nova-font" v-html="this.navItem.text31">
                                            TU proyecto <br> de manera integral.
                                        </h1>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="pl-xl-12" justify-xl="end">
                                <v-col xl="10" class="pr-xl-2 pl-xl-6">
                                    <div class="display-1 greytxt--text" :class="{'title' : ['lg', 'xl'].includes(this.$vuetify.breakpoint.name), 'headline' : this.$vuetify.breakpoint.name == 'xs'}">
                                        <p class="nova-font pb-0" v-html="this.navItem.text32">
                                            Desde la concepción de la idea de negocio hasta su puesta en marcha. La amplia diversidad en especialidades del grupo, como la Construcción, Hostelería, Logística, Mercadotecnia, Diseño y Negocios, nos permite crear soluciones desde un enfoque holístico con resultados tangibles y medibles.
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" lg="7" class="px-0 d-none d-md-flex">
                            <v-img :src="image('img/mockup-blanco','jpg')" cover height="140vh" position="left center" alt=""></v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-spacer class="d-none d-md-flex"></v-spacer>
            </v-card>

            <footer-component></footer-component>
        </v-container>
    </v-content>
</template>
<script>

import { image, bgImage } from "@/mixins/images";

export default {
    mixins: [image, bgImage],
    mounted(){
        this.$store.state.appBarColor = 'white'
        this.$store.state.showScrollBtn = true
        this.$store.state.appBarDark = false
    },
    data(){
        return {
            slider: 0
        }
    },
    computed:{
        navItem(){
            return{
               title : this.$i18n.t("about.title"),
               text1 : this.$i18n.t("about.text1"),
               title1 : this.$i18n.t("about.title1"),
               text2:  this.$i18n.t("about.text2"),
               text30:  this.$i18n.t("about.text3[0]"),
               text31:  this.$i18n.t("about.text3[1]"),
               text32:  this.$i18n.t("about.text3[2]"),
               buttons: {
                   contact: this.$i18n.t("buttons.contact")
               }
            }
        }
    }
}
</script>