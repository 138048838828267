












































import Vue from 'vue';
// import HelloWorld from './components/HelloWorld.vue';
import Navbar from '@/components/Navbar.vue'

export default Vue.extend({
  name: 'App',

  components: {
    Navbar
  },
  computed: {
    showFab() {
      return this.$store.state.offsetTop > 60;
    },
  },
  data: () => ({
      cookiesAlert: true
  }),
  mounted(){
    // this.showCookies()
  },
  methods: {
    showCookies(){
      const cookies = document.cookie.split('; ')
      this.cookiesAlert = cookies.includes('agreement=true')
    },
    onScroll() {
      this.$store.state.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
    },
    scrollToTop() {
      this.$vuetify.goTo(0)
    },
  },
});
