<template>
    <v-content>
        <v-container class="pa-0" fluid>
            <v-card flat tile class="d-flex align-center flex-column height-100">
                <v-card-text>
                    <v-row no-gutters>
                        <v-col class="offset-md-1">
                            <router-link to="/" @click.native="$scrollToTop">
                                <v-btn text class="font-weight-bold">{{$t("buttons.goback")}} <span class="line black ml-2" :class="{'white': ($store.state.appBarDark || $vuetify.theme.dark)}"></span></v-btn>
                            </router-link>
                        </v-col>
                        <v-col cols="2" class="center-text ">
                            <v-img src="@/assets/eon-wms.png" width="50%"></v-img>
                        </v-col>
                        <v-col cols="1"></v-col>
                    </v-row>
                </v-card-text>
                <v-card-text class="py-0">
                    <v-row justify="center" no-gutters>
                        <v-col cols="9" class="display-2 secondary--text">
                            <span class="nova-font" v-html="this.navItem.title1">Sistema</span>
                            <div>
                                <span class="display-4 font-weight-bold">
                                    <span class="nova-font">EON</span>
                                </span> 
                                <span class="nova-font"> WMS</span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="8" class="offset-md-1 title greytxt--text">
                            <div class="nova-font" v-html="this.navItem.text1">
                                Controla fácilmente el flujo de tu almacén, con <b>EON</b> puedes administrar de manera sencilla tu inventario de mercancías, la distribución de tu layout, tu cartera de clientes y proveedores, el activo fijo y todos los elementos relacionados con la operación de tu almacén. 
                            </div> <br>
                            <div class="nova-font" v-html="this.navItem.text2">
                                Nuestra solución es ideal para almacenes tradicionales, <b>Cross Dock, RFE y de fulfillment,</b> por sus características es compatible con los lineamientos operativos de <b>IMMEX</b>.
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
            </v-card>
            <v-card height="100%" width="100%" flat tile>
                <v-card-text>
                    
                </v-card-text>
            </v-card>
            <v-card id="content-section" height="100%" width="100%" flat tile class="">
                <v-card-text class="px-md-0">
                    <v-row no-gutters>
                        <v-col cols="1"></v-col>
                        <v-col cols="1" class="d-flex justify-center pt-md-10">
                            <v-card height="55%" width="60%" color="grey darken-3" flat tile class="mt-md-12"></v-card>
                        </v-col>
                        <v-col>
                            <v-row no-gutters>
                                <v-col cols="4" class="offset-md-1 d-flex align-end">
                                    <div>
                                        <div class="display-2 secondary--text font-weight-bold text-uppercase">
                                            <span class="nova-font" v-html="this.navItem.text3.titulo">Gestión de inventario</span>
                                        </div>
                                        <div class="title">
                                            <div class="nova-font" v-for="(list,index) in this.navItem.text3.list" :key="index">
                                                <span class="secondary--text">-</span> <span v-html="list"></span> <br>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="7">
                                    <v-img src="@/assets/1-2.png" class="mb-md-6"></v-img>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="8" class="display-4 offset-md-1 text-right grey--text text--lighten-1 text-uppercase">
                                    <h1 class="nova-font" :class="{'line-height-10rem': ['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)}" v-html="this.navItem.title2">¿Por qué EON?</h1>
                                </v-col>
                            </v-row>
                            <v-row align="end">
                                <v-col md="3" class="mb-md-12 mt-md-n12 offset-md-1">
                                    <div class="display-2 secondary--text font-weight-bold text-uppercase mb-md-2">
                                        <span class="nova-font" v-html="this.navItem.text4.titulo">Control de acceso</span>
                                    </div>
                                    <div class="title">
                                        <div class="nova-font" v-for="(list,index) in this.navItem.text4.list" :key="index">
                                            <span class="secondary--text">-</span> <span v-html="list"></span> <br>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col md="3" class="text-right offset-md-2">
                                    <div class="display-2 secondary--text font-weight-bold text-uppercase mb-md-2">
                                        <span class="nova-font" v-html="this.navItem.text5.titulo">Ubicación de tus productos</span>
                                    </div>
                                    <div class="title">
                                         <div class="nova-font" v-for="(list,index) in this.navItem.text5.list" :key="index">
                                            <span class="secondary--text">-</span> <span v-html="list"></span> <br>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="3" class="mb-md-12 mt-md-n6 offset-md-1">
                                    <div class="display-2 secondary--text font-weight-bold text-uppercase mb-md-2">
                                        <span class="nova-font" v-html="this.navItem.text6.titulo">Fulfillment</span>
                                    </div>
                                    <div class="title">
                                         <div class="nova-font" v-for="(list,index) in this.navItem.text6.list" :key="index">
                                            <span class="secondary--text">-</span> <span v-html="list"></span> <br>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col md="3" class="text-right offset-md-2">
                                    <div class="display-2 secondary--text font-weight-bold text-uppercase mb-md-2">
                                        <div class="display-1 font-weight-bold">
                                            <span class="nova-font"  v-html="this.navItem.text7.titulo">Administración</span>
                                        </div>
                                        <div>
                                            <span class="nova-font">RFE</span>
                                        </div>
                                    </div>
                                    <div class="title">
                                         <div class="nova-font" v-for="(list,index) in this.navItem.text7.list" :key="index">
                                            <span class="secondary--text">-</span> <span v-html="list"></span> <br>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card height="100%" width="100%" flat tile class="mt-md-n12">
                <v-card-text class="px-md-0 mt-md-n12">
                    <v-row no-gutters class="mt-md-n12">
                        <v-col cols="1" class="black"></v-col>
                        <v-col cols="1" class="black"></v-col>
                        <v-col>
                            <v-row>
                                <v-col cols="1" class="black" v-show="['md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)"></v-col>
                                <v-col md="5" class=" black pr-md-12">
                                    <div class="headline secondary--text">
                                        <span class="nova-font"><span class="white--text headline">⟶</span> <span v-html="this.navItem.title3">Integra fácilmente la administración de tus inventarios con tecnología RFID para garantizar mayor precisión en tu operación </span></span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card width="100%" flat tile>
                <v-card-text class="px-md-0">
                    <v-row justify="center">
                        <v-col md="11">
                            <v-img src="@/assets/infografia.png"></v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="py-md-12">
                <v-card-text class="py-md-12">
                    <v-row justify="center" class="py-md-12">
                        <v-col cols="12" md="6" class="text-center">
                            <div class="display-1">
                                <span class="nova-font" v-html="this.navItem.title4">Nuestra solución es <b>adaptable a tu negocio</b>, podemos personalizar módulos de acuerdo a tus necesidades o <b>generar complementos para garantizar la cobertura al completo de tus procesos.</b></span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card>
                <v-card-text class="px-md-0">
                    <v-row no-gutters>
                        <v-col cols="12" md="3" class="offset-md-3 pr-md-12">
                            <div class="secondary--text display-2"><span class="nova-font" v-html="this.navItem.title5[0]">Integración</span></div>
                            <div class="secondary--text display-3 font-weight-bold"><span class="nova-font" v-html="this.navItem.title5[1]">VÍA API</span></div>
                            <div class="headline"><div class="nova-font" v-html="this.navItem.text8">¿Tienes un sistema de ventas, una tienda en línea o distribuyes productos vía Amazon, Mercado Libre o algún otro marketplace y necesitas conectarlo con tu almacén?</div></div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-img src="@/assets/products/eon-wms/eon-wms-6.png"></v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card>
                <v-card-text class="pa-md-0">
                    <v-row no-gutters>
                        <v-col cols="12" md="9">
                            <v-card flat tile>
                                <v-img src="@/assets/products/eon-wms/eon-wms-5.png"></v-img>
                                <v-overlay absolute z-index="0" color="black"></v-overlay>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="3" class="d-flex align-center ml-md-n12 pa-6">
                            <v-card color="black" class="ml-md-n12" flat height="60%">
                                <v-card-title>
                                    <div class="display-1 mb-4" :class="{'headline': ['xs', 'sm'].includes(this.$vuetify.breakpoint.name), 'title' : this.$vuetify.breakpoint.name == 'lg'}">
                                        <h4 class="nova-font secondary--text font-weight-thin" v-html="this.navItem.banner.title">Portafolio</h4>
                                    </div>
                                </v-card-title>
                                <v-card-text class="white--text py-0 font-weight-thin">
                                    <div class="title" :class="{'subtitle-1': this.$vuetify.breakpoint.name == 'lg'}">
                                        <p class="nova-font mb-0"><span class="font-weight-bold mb-0"  v-html="this.navItem.banner.text[0]"></span></p>
                                    </div>

                                </v-card-text>
                                <v-card-text class="white--text pt-0">
                                    <v-row>
                                        <v-col cols="12" md="12" xl="8" class="py-xl-0 pr-xl-12 subtitle-1" :class="{'subtitle-2': this.$vuetify.breakpoint.name == 'lg'}">
                                            <p class="nova-font mt-4"  v-html="this.navItem.banner.text[1]">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris a nibh tempus, semper mauris et, condimentum nisi.
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-card-text class="white--text pt-0">
                                    <v-row>
                                        <v-col cols="12" md="12" xl="8" class="py-xl-0 pr-xl-12 subtitle-1" :class="{'subtitle-2': this.$vuetify.breakpoint.name == 'lg'}">
                                            <v-btn class="font-weight-bold" color="orange" large outlined v-html="this.navItem.banner.button"></v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        <footer-component></footer-component>
        </v-container>
    </v-content>
</template>
<script>
export default {
    computed:{
        navItem(){
            return{
                title1: this.$i18n.t("eon.title1"),
                text1: this.$i18n.t("eon.text1"),
                text2: this.$i18n.t("eon.text2"),
                title2: this.$i18n.t("eon.title2"),
                text3: {
                    titulo: this.$i18n.t("eon.text3.titulo"),
                    list: this.$i18n.t("eon.text3.list"),
                },
                text4: {
                    titulo: this.$i18n.t("eon.text4.titulo"),
                    list: this.$i18n.t("eon.text4.list"),
                },
                text5: {
                    titulo: this.$i18n.t("eon.text5.titulo"),
                    list: this.$i18n.t("eon.text5.list"),
                },
                text6: {
                    titulo: this.$i18n.t("eon.text6.titulo"),
                    list: this.$i18n.t("eon.text6.list"),
                },
                text7: {
                    titulo: this.$i18n.t("eon.text7.titulo"),
                    list: this.$i18n.t("eon.text7.list"),
                },
                title3: this.$i18n.t("eon.title3"),
                title4: this.$i18n.t("eon.title4"),
                title5: this.$i18n.t("eon.title5"),
                text8: this.$i18n.t("eon.text8"),
                banner:{
                    title: this.$i18n.t("eon.banner.title"),
                    text: this.$i18n.t("eon.banner.text"),
                    button: this.$i18n.t("eon.banner.button")
                }               
            }
        }
    }
}
</script>
<style>
.line-height-10rem{
    line-height: 10rem;
}
</style>