<template>
<div>
    <v-card class="portrait-fill flexbox" min-width="100%" :style="bgPortrait(this.navItem.chronos.background.name,this.navItem.chronos.background.extension)" flat tile>
        
        <div>
            <v-row>
                <v-col cols="12" class="text-center">
                    <div class="display-4" :class="{'display-2': this.$vuetify.breakpoint.name == 'xs'}">
                        <img :src="image(this.navItem.chronos.logo.name,this.navItem.chronos.logo.extension)" :alt="this.navItem.chronos.logo.name" class="logo-size" />                               
                    </div>
                </v-col>            
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="6" class="headline text-center white--text" :class="{'title': this.$vuetify.breakpoint.name == 'xs'}">
                    <div class="nova-font pr-md-8 px-xl-8" v-html="this.navItem.chronos.description"></div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="6" class="text-center">
                    <div class="pr-md-8 px-xl-8">
                        
                        <v-btn @click.native="$scrollToTop" to="proyectos/chronos" text class="font-weight-bold headline" color="white" x-large><span class="nova-font">Ver más</span></v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>

    </v-card>




    <v-container class="pa-0" fluid>
        <v-card min-width="100%" flat tile id="content-section">
            <v-card-text class="py-12">
                <v-row>
                    <v-col class="offset-md-1">
                        <div>
                            <h1 class="nova-font font-weight-bold secondary--text">Portafolio</h1>
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                    <v-col md="10">
                        <v-row>
                            <v-spacer></v-spacer>
                            
                            <v-col cols="12" md="7" class="text-center">
                                <router-link @click.native="$scrollToTop" to="proyectos/indexmerca" class="pa-0 ma-0">
                                    <v-hover v-slot:default="{ hover }">
                                        <v-card height="450px" :img="image('projects/indexmerca/Portada','png')">
                                            <!-- <v-img cover height="100%" gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"></v-img> -->
                                            <v-card-text class="px-0 d-flex flex-column v-card--filter" style="height: 100%;">
                                                <v-spacer></v-spacer>                                    
                                                <div class="d-flex justify-center align-center px-12 py-4">
                                                    <v-fade-transition>
                                                        <v-img v-show="!hover" :src="image('logos/logotipo-indexmerca-blanco','png')" contain max-width="30%"></v-img>
                                                    </v-fade-transition>
                                                </div>
                                                <v-spacer></v-spacer>
                                            </v-card-text>
                                            <v-expand-transition>
                                                <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal display-3 white--text" style="height: 100%;">
                                                    <v-card-text class="px-0 d-flex flex-column" style="height: 100%;">
                                                        <v-spacer></v-spacer>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                <div class="title"><div class="white--text nova-font font-weight-bold"><br>PROYECTO APP</div></div>
                                                            </v-col>
                                                        </v-row>
                                                        <div class="d-flex justify-center align-center px-12 py-4">
                                                            <v-img :src="image('logos/logotipo-indexmerca-blanco','png')" contain max-width="30%"></v-img>
                                                        </div>
                                                        <v-row justify="center" no-gutters>
                                                            <v-col cols="12" md="4">
                                                                <div class="subtitle-1">
                                                                    <div class="white--text nova-font">
                                                                        Desarrollo de sistema y aplicación especializado para proyecto.
                                                                    </div>
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                        <v-spacer></v-spacer>
                                                    </v-card-text>
                                                    <!-- <v-img src="@/assets/logos/sacar.png" contain></v-img> -->
                                                </div>
                                            </v-expand-transition>
                                        </v-card>
                                    </v-hover>
                                </router-link>
                            </v-col>
                            <v-col cols="12" md="3" class="text-center">
                                <router-link @click.native="$scrollToTop" to="proyectos/chronos" class="pa-0 ma-0">
                                    <v-hover v-slot:default="{ hover }">
                                        <v-card height="450px" :img="$store.state.mediaUrl+'/img/1-25.png'">
                                            <!-- <v-img cover height="100%" gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"></v-img> -->
                                            <v-card-text class="px-0 d-flex flex-column v-card--filter" style="height: 100%;">
                                                <v-spacer></v-spacer>                                    
                                                <div class="d-flex justify-center align-center px-12 py-4">
                                                    <v-fade-transition>
                                                        <v-img v-show="!hover" :src="image('logos/chronos','png')" contain max-width="80%"></v-img>
                                                    </v-fade-transition>
                                                </div>
                                                <v-spacer></v-spacer>
                                            </v-card-text>
                                            <v-expand-transition>
                                                <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal display-3 white--text" style="height: 100%;">
                                                    <v-card-text class="px-0 d-flex flex-column" style="height: 100%;">
                                                        <v-spacer></v-spacer>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                <div class="title"><div class="white--text nova-font font-weight-bold"><br>PROYECTO APP</div></div>
                                                            </v-col>
                                                        </v-row>
                                                        <div class="d-flex justify-center align-center px-12 py-4">
                                                            <v-img :src="image('logos/chronos','png')" contain max-width="80%"></v-img>
                                                        </div>
                                                        <v-row justify="center" no-gutters>
                                                            <v-col cols="12">
                                                                <div class="subtitle-1">
                                                                    <div class="white--text nova-font">
                                                                        Desarrollo de sistema y aplicación especializado para proyecto.
                                                                    </div>
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                        <v-spacer></v-spacer>
                                                    </v-card-text>
                                                    <!-- <v-img src="@/assets/logos/sacar.png" contain></v-img> -->
                                                </div>
                                            </v-expand-transition>
                                        </v-card>
                                    </v-hover>
                                </router-link>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="5" class="text-center">
                                <router-link @click.native="$scrollToTop" to="proyectos/yego" class="pa-0 ma-0">
                                    <v-hover v-slot:default="{ hover }">
                                        <v-card height="450px" :img="image('projects/yego/portada','png')" >
                                            <!-- <v-img cover height="100%" gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"></v-img> -->
                                            <v-card-text class="px-0 d-flex flex-column v-card--filter" style="height: 100%;">
                                                <v-spacer></v-spacer>                                    
                                                <div class="d-flex justify-center">
                                                    <v-fade-transition>
                                                        <v-img v-show="!hover" :src="image('logos/Yego-white','png')" contain max-width="30%"></v-img>
                                                    </v-fade-transition>
                                                </div>
                                                <v-spacer></v-spacer>
                                            </v-card-text>
                                            <v-expand-transition>
                                                <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal display-3 white--text" style="height: 100%;">
                                                    <v-card-text class="px-0 d-flex flex-column" style="height: 100%;">
                                                        <v-spacer></v-spacer>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                <div class="title"><div class="white--text nova-font font-weight-bold">PROYECTO APP </div></div>
                                                            </v-col>
                                                        </v-row>
                                                        <div class="d-flex justify-center align-center display-3">
                                                            <v-img :src="image('logos/Yego-white','png')" contain max-width="30%"></v-img>
                                                        </div>
                                                        <v-row justify="center" no-gutters>
                                                            <v-col cols="12">
                                                                <div class="subtitle-1">
                                                                    <div class="white--text nova-font">
                                                                        Desarrollo de sistema y aplicación especializado para proyecto.
                                                                    </div>
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                        <v-spacer></v-spacer>
                                                    </v-card-text>
                                                    <!-- <v-img src="@/assets/logos/sacar.png" contain></v-img> -->
                                                </div>
                                            </v-expand-transition>
                                        </v-card>
                                    </v-hover>
                                </router-link>
                            </v-col>
                            <v-col cols="12" md="5" class="text-center">
                                <router-link @click.native="$scrollToTop" to="proyectos/sacar" class="pa-0 ma-0">
                                    <v-hover v-slot:default="{ hover }">
                                        <v-card height="450px" :img="$store.state.mediaUrl+'/img/GREENBRIER4-1024x576-1024x576.png'">
                                            <!-- <v-img cover height="100%" gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"></v-img> -->
                                            <v-card-text class="px-0 d-flex flex-column v-card--filter" style="height: 100%;">
                                                <v-spacer></v-spacer>                                    
                                                <div class="d-flex justify-center">
                                                    <v-fade-transition>
                                                    <v-img v-show="!hover" src="@/assets/logos/sacar.png" contain max-width="70%"></v-img>
                                                    </v-fade-transition>
                                                </div>
                                                <v-spacer></v-spacer>
                                            </v-card-text>
                                            <v-expand-transition>
                                                <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal display-3 white--text" style="height: 100%;">
                                                    <v-card-text class="px-0 d-flex flex-column" style="height: 100%;">
                                                        <v-spacer></v-spacer>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                <div class="title"><div class="white--text nova-font font-weight-bold">PROYECTO APP </div></div>
                                                            </v-col>
                                                        </v-row>
                                                        <div class="d-flex justify-center align-center display-3">
                                                            <v-img src="@/assets/logos/sacar.png" contain max-width="70%"></v-img>
                                                        </div>
                                                        <v-row justify="center" no-gutters>
                                                            <v-col cols="12">
                                                                <div class="subtitle-1">
                                                                    <div class="white--text nova-font">
                                                                        Desarrollo de sistema y aplicación especializado para proyecto.
                                                                    </div>
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                        <v-spacer></v-spacer>
                                                    </v-card-text>
                                                    <!-- <v-img src="@/assets/logos/sacar.png" contain></v-img> -->
                                                </div>
                                            </v-expand-transition>
                                        </v-card>
                                    </v-hover>
                                </router-link>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="7" class="text-center">
                                <router-link @click.native="$scrollToTop" to="proyectos/dmadmin" class="pa-0 ma-0">
                                    <v-hover v-slot:default="{ hover }">
                                        <v-card height="450px" :img="image('projects/dmadmin/portada','png')">
                                            <!-- <v-img cover height="100%" gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"></v-img> -->
                                            <v-card-text class="px-0 d-flex flex-column v-card--filter" style="height: 100%;">
                                                <v-spacer></v-spacer>                                    
                                                <div class="d-flex justify-center">
                                                    <v-fade-transition>
                                                    <v-img v-show="!hover" :src="image('logos/dmadmin-logo','png')" contain max-width="30%"></v-img>
                                                    </v-fade-transition>
                                                </div>
                                                <v-spacer></v-spacer>
                                            </v-card-text>
                                            <v-expand-transition>
                                                <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal display-3 white--text" style="height: 100%;">
                                                    <v-card-text class="px-0 d-flex flex-column" style="height: 100%;">
                                                        <v-spacer></v-spacer>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                <div class="title"><div class="white--text nova-font font-weight-bold">PROYECTO APP </div></div>
                                                            </v-col>
                                                        </v-row>
                                                        <div class="d-flex justify-center align-center display-3">
                                                            <v-img :src="image('logos/dmadmin-logo','png')" contain max-width="30%"></v-img>
                                                        </div>
                                                        <v-row justify="center" no-gutters>
                                                            <v-col cols="12">
                                                                <div class="subtitle-1">
                                                                    <div class="white--text nova-font">
                                                                        Desarrollo de sistema y aplicación especializado para proyecto.
                                                                    </div>
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                        <v-spacer></v-spacer>
                                                    </v-card-text>
                                                    <!-- <v-img src="@/assets/logos/sacar.png" contain></v-img> -->
                                                </div>
                                            </v-expand-transition>
                                        </v-card>
                                    </v-hover>
                                 </router-link>                               
                            </v-col>
                            <v-col cols="12" md="3" class="text-center">
                                <router-link @click.native="$scrollToTop" to="proyectos/meta" class="pa-0 ma-0">
                                    <v-hover v-slot:default="{ hover }">
                                        <v-card height="450px" :img="image('projects/meta/Meta','png')">
                                            <!-- <v-img cover height="100%" gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.5)"></v-img> -->
                                            <v-card-text class="px-0 d-flex flex-column v-card--filter" style="height: 100%;">
                                                <v-spacer></v-spacer>                                    
                                                <div class="d-flex justify-center">
                                                    <v-fade-transition>
                                                    <v-img v-show="!hover" :src="image('logos/grupo-valoran','png')" contain max-width="70%"></v-img>
                                                    </v-fade-transition>
                                                </div>
                                                <v-spacer></v-spacer>
                                            </v-card-text>
                                            <v-expand-transition>
                                                <div v-if="hover" class="d-flex transition-fast-in-fast-out v-card--reveal display-3 white--text" style="height: 100%;">
                                                    <v-card-text class="px-0 d-flex flex-column" style="height: 100%;">
                                                        <v-spacer></v-spacer>
                                                        <v-row no-gutters>
                                                            <v-col>
                                                                <div class="title"><div class="white--text nova-font font-weight-bold">PROYECTO APP </div></div>
                                                            </v-col>
                                                        </v-row>
                                                        <div class="d-flex justify-center align-center display-3">
                                                            <v-img :src="image('logos/grupo-valoran','png')" contain max-width="70%"></v-img>
                                                        </div>
                                                        <v-row justify="center" no-gutters>
                                                            <v-col cols="12">
                                                                <div class="subtitle-1">
                                                                    <div class="white--text nova-font">
                                                                        Desarrollo de sistema y aplicación especializado para proyecto.
                                                                    </div>
                                                                </div>
                                                            </v-col>
                                                        </v-row>
                                                        <v-spacer></v-spacer>
                                                    </v-card-text>
                                                    <!-- <v-img src="@/assets/logos/sacar.png" contain></v-img> -->
                                                </div>
                                            </v-expand-transition>
                                        </v-card>
                                    </v-hover>
                                 </router-link>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="4" xl="3" class="offset-md-1">
                        <div class="title font-weight-bold">
                            <span class="nova-font">
                                <div>¿Quiéres saber más?</div>
                                <div>¿Tienes dudas?</div>
                            </span>
                        </div>
                        <div class="title">
                            <span class="nova-font">
                                <div>Te estamos escuchando </div>
                                <div>Agenda un cita o llámanos y te ayudaremos a resolverlas.</div>
                            </span>
                        </div>
                        <div>
                            <router-link to="contacto">
                            <v-hover>
                                <v-btn  tile class="btn-hover font-weight-bold"  slot-scope="{hover}" :class="`${hover? 'white--text': 'orange--text'}`"  large outlined>Trabaja con nosotros</v-btn>
                            </v-hover>
                            </router-link>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <footer-component></footer-component>
    </v-container>
</div>
</template>
<script>
// import Footer from '@/components/Footer.vue'
import { image, bgImage } from "@/mixins/images";

export default {
  mixins: [image, bgImage],
  name: 'Contact',
  components: {
    // Footer
  },

  computed:{
    navItem() {
      return {
          chronos:{
              background:{
                  name:"portada-chronos",
                  extension:"png"
              },
              logo:{
                  name:"logos/logo-chronos",
                  extension:"png"
              },
              description: this.$i18n.t("portafolio.chronos.description"),
              link: this.$i18n.t("portafolio.chronos.link")
          }
      }
    }      
  },

  methods:{
     changeScroll(){
        const y = window.scrollY
        if ( y > 50){
            this.$store.state.appBarColor = 'white'
            this.$store.state.showScrollBtn = true
            this.$store.state.appBarDark = false
            // this.$vuetify.theme.dark = false
        }
        else{
            this.$store.state.showScrollBtn = true
            this.$store.state.appBarColor = 'transparent'
            this.$store.state.appBarDark = true
        }

     } 
  },

  mounted(){
      this.$store.state.showScrollBtn = true
      this.$store.state.appBarColor = 'transparent'
      this.$store.state.appBarDark = true

      window.addEventListener('scroll', this.changeScroll);
      
  }
}
</script>

<style>
.height-100vh{
    min-height: 100vh;
    /* max-height: 100vh; */
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  /* opacity: 0.7; */
  /* secondary */
  background-color: rgba(178, 206, 47, 0.8);
  position: absolute;
  width: 100%;
}
.v-card--filter{
    /* filter: grayscale(100%); */
    background-color: rgba(0, 0, 0, 0.5);
}

</style>
