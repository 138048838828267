<template>
    <v-footer :dark="darkIcons" :color="cardColor" padless width="100%">
        <v-card flat tile class="" width="100%" :color="cardColor">
            <v-card-text class="px-0 py-1">
                <v-row justify="center" align="center">
                    <v-col cols="10" md="10" class="px-0 pa-1">
                        <v-row no-gutters>
                            <v-col :class="{'text-center': this.$vuetify.breakpoint.name == 'xs'}">
                                <!-- span class="black--text" :class="{'white--text': darkIcons}">Siguenos en:</span -->
                            </v-col>
                        </v-row>
                        <v-row no-gutters align="center">
                            <v-col cols="12" md="6" :class="{'text-center': this.$vuetify.breakpoint.name == 'xs'}">
                                <!--v-btn class="mx-1 black--text" icon>
                                    <v-icon :class="{'white--text': darkIcons}">mdi-facebook</v-icon>
                                </v-btn>
                                <v-btn class="mx-1 black--text" icon>
                                    <v-icon :class="{'white--text': darkIcons}">mdi-twitter</v-icon>
                                </v-btn>
                                <v-btn class="mx-1 black--text" icon>
                                    <v-icon :class="{'white--text': darkIcons}">mdi-linkedin</v-icon>
                                </v-btn  -->
                            </v-col>
                            <v-col cols="12" md="5" class="text-right subtitle-1" :class="{'text-center': this.$vuetify.breakpoint.name == 'xs'}">
                                <div class="nova-font black--text" :class="{'white--text': darkIcons}"><v-btn text depressed class="capitalize-text black--text px-1" :class="{'white--text': darkIcons}">Privacy Policy</v-btn> - <span class="px-1">{{ new Date().getFullYear() }} Yntech all rights reserved</span></div>
                            </v-col>
                            <v-col cols="12" md="1" class="text-right text-center">
                                <v-btn class="black--text capitalize-text nova-font" small :class="{'white--text': !$vuetify.theme.dark}" @click="$vuetify.theme.dark = !$vuetify.theme.dark" text>{{$vuetify.theme.dark ? 'Light mode' : 'Dark mode' }}</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    props: {'color': String, 'dark': Boolean},
    computed: {
        cardColor(){
            if(typeof this.color !== 'undefined')
                return this.color
            return (this.$vuetify.theme.dark) ? 'white' : 'gryntech'
        },
        darkIcons(){
            return !this.$vuetify.theme.dark
        }
    }
}
</script>
<style>
.capitalize-text{
    text-transform: capitalize !important;
}
</style>