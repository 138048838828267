import ProjectTemplate from '../views/Projects/Template.vue'
import ProjectIndexmerca from '../views/Projects/IndexmercaERP.vue'
import ProjectMeta from '../views/Projects/Meta.vue'
import ProjectYego from '../views/Projects/YegoERP.vue'
import ProjectEon from '../views/Projects/Eon.vue'
import ProjectChronos from '../views/Projects/Chronos.vue'
import ProjectSacar from '../views/Projects/Sacar.vue'
import ProjectDM from '../views/Projects/DmAdmin.vue'


const projects=[
    {
		path: 'proyectos/template',
		name: 'Project-Template',
		component: ProjectTemplate
	},
	{
		path: 'proyectos/indexmerca',
		name: 'Project-Indexmerca',
		component: ProjectIndexmerca
	},
	{
		path: 'proyectos/meta',
		name: 'Project-Meta',
		component: ProjectMeta
	},
	{
		path: 'proyectos/yego',
		name: 'Project-Yego',
		component: ProjectYego
	},
	{
		path: 'proyectos/eon',
		name: 'Project-Eon',
		component: ProjectEon
	},
	{
		path: 'proyectos/chronos',
		name: 'Project-Chronos',
		component: ProjectChronos
	},
	{
		path: 'proyectos/sacar',
		name: 'Project-ERP-sacar',
		component: ProjectSacar
	},
	{
		path: 'proyectos/dmadmin',
		name: 'Project-ERP-DmAdmin',
		component: ProjectDM
	}
];

export default projects