<template>
    <v-content>
        <v-container class="pa-0" :class="{/*'fill-height': ($store.state.offsetTop < window.innerHeight) */}" fluid>
            <v-card flat tile class="d-flex align-center flex-column" dark color="#1C1B20">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" md="6" class="offset-md-1">REGRESAR A LA GALERÍA</v-col>
                        <v-col cols="6" md="2" xl="1">
                            <div style="border-top: 3px solid #95b200;">CLIENTE</div>
                            <div>Chronos Almacenaje</div>
                        </v-col>
                        <v-col cols="6" md="2" xl="1" class="offset-xl-1">
                            <div style="border-top: 3px solid #95b200;">ROLE</div>
                            <div>Desarrollo, UX / UI</div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-text class="pa-0 pa-md-4 pa-xl-4 mb-xl-12"></v-card-text>
                <v-card-text class="mt-xl-12">
                    <v-row align="center">
                        <v-col md="7" class="offset-md-1">
                            <div class="font-weight-bold white--text" :class="{'display-4': this.$vuetify.breakpoint.name == 'xl', 'display-3': this.$vuetify.breakpoint.name != 'xl', 'display-2': this.$vuetify.breakpoint.name == 'xs'}">
                                <span class="nova-font">EON</span>
                            </div>
                            <div class="title" :class="{'subtitle-1': this.$vuetify.breakpoint.name == 'xs'}">
                                <span class="nova-font">Creamos un Sistema de Control de Almacenes (WMS) con todas las funciones necesarias para administrar la ejecución de un almacén de cualquier tamaño, con características especiales que lo hacen útil para recintos fiscalizados y fulfillment.</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text>
                    <v-row align="center" no-gutters>
                        <v-col md="7" class="offset-md-1">
                            <div class="secondary--text font-weight-bold" :class="{'display-1': this.$vuetify.breakpoint.name == 'xl', 'headline': this.$vuetify.breakpoint.name != 'xl'}">
                                <span class="nova-font">Objetivo</span>
                            </div>
                            <div class="title" :class="{'subtitle-1': this.$vuetify.breakpoint.name == 'xs'}">
                                <span class="nova-font">Administrar de forma efectiva y eficiente el flujo de mercancía dentro del almacén, con trazabilidad al completo de cada producto y políticas de surtido de primer nivel. </span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card flat tile class="d-flex align-center flex-column height-100" dark color="#C4DBE9">
                <v-card-text>
                    <v-row align="center">
                        <v-col class="d-flex justify-center justify-md-end">
                            <v-card color="transparent" flat width="50%">
                                <v-img src="@/assets/projects/template/Prueba4.png"></v-img>
                            </v-card>
                        </v-col>
                        <v-col class="d-flex justify-center justify-md-center">
                            <v-card color="transparent" flat width="60%">
                                <v-img src="@/assets/projects/template/Prueba4.png"></v-img>
                            </v-card>
                        </v-col>
                        <v-col class="d-flex justify-center justify-md-start">
                            <v-card color="transparent" flat width="50%">
                                <v-img src="@/assets/projects/template/Prueba4.png"></v-img>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card flat tile class="d-flex align-center flex-column height-100" dark color="#1C1B20">
                <v-card-text class="py-xl-10">
                    <v-row align="center" no-gutters>
                        <v-col md="7" class="offset-md-1">
                            <div class="secondary--text font-weight-bold" :class="{'display-1': this.$vuetify.breakpoint.name == 'xl', 'headline': this.$vuetify.breakpoint.name != 'xl'}">
                                <span class="nova-font">Concepto</span>
                            </div>
                            <div class="title" :class="{'subtitle-1': this.$vuetify.breakpoint.name == 'xs'}">
                                <span class="nova-font">El WMS de Chronos se fundamenta en proveer al personal las herramientas y la información necesarias para que sus procesos de almacenaje y flujo de mercancía sean ejecutados de forma eficiente y sin contratiempos..</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text class="py-0">
                    <v-row align="center">
                        <v-col md="10" class="offset-md-1">
                            <v-card color="#e892c5" height="100%" tile>
                                <v-card-text>
                                    <v-row align="center">
                                        <v-col class="d-flex justify-center">
                                            <v-card color="transparent" flat>
                                                <v-img src="@/assets/projects/template/Prueba2.png" width="95%"></v-img>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text class="py-0">
                    <v-row>
                        <v-col md="5" class="offset-md-1">
                            <v-card tile color="transparent" flat>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="6" md="6" class="d-flex justify-center">
                                            <v-card color="transparent" flat>
                                                <v-img src="@/assets/projects/template/Prueba4.png"></v-img>
                                                <v-card-text></v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="6" md="6" class="d-flex justify-center flex-column">
                                            <v-card-text></v-card-text>
                                            <v-card color="transparent" flat class="mt-md-12">
                                                <v-img src="@/assets/projects/template/Prueba4.png"></v-img>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card color="#C4DBE9" tile class="mb-2">
                                <v-card-text class="py-0">
                                    <v-row align="center">
                                        <v-col class="d-flex justify-end">
                                            <v-card color="transparent" flat>
                                                <v-img src="@/assets/projects/template/Prueba3.png"></v-img>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-row align="center" no-gutters>
                                <v-col>
                                    <div class="secondary--text title font-weight-bold">
                                        <span class="nova-font">Marca</span>
                                    </div>
                                    <div class="title" :class="{'subtitle-1': this.$vuetify.breakpoint.name == 'xs'}">
                                        <span class="nova-font">Chronos ofrece a sus clientes soluciones logísticas especializadas en e-commerce y abastecimiento de comercio, con variedad de alternativas en almacenaje, fulfillment y comercio exterior.</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-footer padless>
                <v-card width="100%">
                    <v-card-text class="pa-0">
                        <v-row align="center">
                            <v-col md="6" class="white d-flex pa-6 subtitle-1 font-weight-bold" :class="{'caption': this.$vuetify.breakpoint.name == 'xs'}">
                                <div class="secondary--text nova-font text-uppercase">
                                    ← Lorem Ipsum
                                </div>
                            </v-col>
                            <v-col md="6" class="secondary d-flex flex-column flex-sm-row pa-6 subtitle-1 font-weight-bold" :class="{'caption': this.$vuetify.breakpoint.name == 'xs'}">
                                <div class="white--text nova-font text-uppercase">
                                    Siguiente proyecto
                                </div>
                                <v-spacer></v-spacer>
                                <div class="white--text nova-font text-uppercase">
                                    Lorem Ipsum →
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-footer>
        </v-container>
    </v-content>
</template>
<script>
export default {
    
}
</script>