import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#2b4d4b',
        secondary: '#95b200',
        accent: '#ff7811',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        gryntech: '#274241',
        greytxt: '#444444'
      },
      dark: {
        primary: '#2b4d4b',
        secondary: '#95b200',
        accent: '#ff7811',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        gryntech: '#274241',
        greytxt: '#ffffff'
        
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
